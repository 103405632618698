import React from 'react';

import AttachFile from 'components/SvgComponents/icons/Attachment';
import ButtonSvg from 'components/SvgComponents/icons/Button';
import ControlSvg from 'components/SvgComponents/icons/Control';
import DisclaimerSvg from 'components/SvgComponents/icons/Disclaimer';
import EmailSvg from 'components/SvgComponents/icons/Email';
import FormulaSvg from 'components/SvgComponents/icons/Formula';
import GridSvg from 'components/SvgComponents/icons/Grid';
import MultipleChoiceSvg from 'components/SvgComponents/icons/MultipleChoice';
import Paragraph from 'components/SvgComponents/icons/Paragraph';
import PhoneSvg from 'components/SvgComponents/icons/Phone';
import ShortAnswerSvg from 'components/SvgComponents/icons/ShortAnswer';
import SignatureSvg from 'components/SvgComponents/icons/Signature';
import SingleChoiceSvg from 'components/SvgComponents/icons/SingleChoice';
import globalMessages from 'translations/messages/global-messages';

import { typeFieldDataConst } from './constants';

export default [
  {
    id: 1,
    name: typeFieldDataConst.shortAnswer,
    icon: <ShortAnswerSvg width="16" height="16" />,
  },
  {
    id: 2,
    name: typeFieldDataConst.paragraph,
    icon: <Paragraph width="16" height="16" />,
  },
  {
    id: 4,
    name: typeFieldDataConst.singleChoice,
    icon: <SingleChoiceSvg width="16" height="16" />,
  },
  {
    id: 5,
    name: typeFieldDataConst.multipleChoice,
    icon: <MultipleChoiceSvg width="16" height="16" />,
  },
  {
    id: 6,
    name: typeFieldDataConst.control,
    icon: <ControlSvg width="16" height="16" />,
  },
  {
    id: 7,
    name: typeFieldDataConst.signature,
    icon: <SignatureSvg width="16" height="16" />,
  },
  {
    id: 8,
    name: typeFieldDataConst.attachment,
    icon: <AttachFile width="16" height="16" />,
  },
  {
    id: 10,
    name: typeFieldDataConst.formLink,
    icon: <GridSvg width="16" height="16" />,
  },
  {
    id: 9,
    name: typeFieldDataConst.button,
    icon: <ButtonSvg width="16" height="16" />,
  },
  {
    id: 11,
    name: typeFieldDataConst.disclaimer,
    icon: <DisclaimerSvg width="16" height="16" />,
  },
  {
    id: 12,
    name: typeFieldDataConst.phoneNumber,
    icon: <PhoneSvg width="16" height="16" />,
  },
  {
    id: 13,
    name: typeFieldDataConst.email,
    icon: <EmailSvg width="16" height="16" />,
  },
  {
    id: 15,
    name: typeFieldDataConst.formula,
    icon: <FormulaSvg width="16" height="16" />,
  },
];

export const getTypeFieldListTranslation = (intl, name) => {
  switch (name) {
    case typeFieldDataConst.shortAnswer:
      return intl.formatMessage(globalMessages.short_answer);
    case typeFieldDataConst.singleChoice:
      return intl.formatMessage(globalMessages.single_choice);
    case typeFieldDataConst.multipleChoice:
      return intl.formatMessage(globalMessages.multiple_choice);
    case typeFieldDataConst.control:
      return intl.formatMessage(globalMessages.control);
    case typeFieldDataConst.signature:
      return intl.formatMessage(globalMessages.signature);
    case typeFieldDataConst.attachment:
      return intl.formatMessage(globalMessages.attachment);
    case typeFieldDataConst.formLink:
      return intl.formatMessage(globalMessages.grid);
    case typeFieldDataConst.disclaimer:
      return intl.formatMessage(globalMessages.disclaimer);
    case typeFieldDataConst.phoneNumber:
      return intl.formatMessage(globalMessages.phone);
    case typeFieldDataConst.email:
      return intl.formatMessage(globalMessages.email);
    case typeFieldDataConst.formula:
      return intl.formatMessage(globalMessages.formula);
    case typeFieldDataConst.button:
      return intl.formatMessage(globalMessages.button);
    default:
      return name;
  }
};
