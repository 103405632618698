/**
 * Created by Alexandru Huszar on 6/7/2021.
 */
import React, { useContext, useEffect, useState } from 'react';

import { Box, FormControlLabel, Link, TextField, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import OverSeatOfferBanner from 'components/Banner/components/OverSeatOfferBanner';
import Button from 'components/Button';
import FabButton from 'components/buttons/FabButton';
import Dialog from 'components/dialogs/Dialog';
import EmptyTable from 'components/EmptyTable';
import InfiniteTableSelect from 'components/InfiniteTableSelect';
import { getItems } from 'components/InfiniteTableSelfStandingV3/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Add from 'components/SvgComponents/icons/Add';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import config from 'config/config';
import { getCurrentModule } from 'containers/GlobalWrapper/modules/actions';
import { selectCurrentModuleMap } from 'containers/GlobalWrapper/modules/selectors';
import { ProjectContext } from 'containers/GlobalWrapper/ProjectDataValidation';
import { selectGroups } from 'containers/OneProjectPage/GroupsPage/selectors';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { useOverSeatLimitOffer } from 'hooks/use-over-seat-limit-offer';
import { Routing } from 'routing/routing';
import combineStyles from 'theme/combineStyles';
import formEngineStyle from 'theme/formEngine-theme';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { authorizations } from 'utils/constants';
import { handleToggleDialog } from 'utils/dialog';
import { getTranslation } from 'utils/localization';
import { normalize } from 'utils/validators';

import AddNewItemDialog from '../../components/AddNewItemDialog';
import { MODULE_SETTINGS } from '../../constants';
import { addCommonObservation, addTrade, saveObservation } from '../actions';
import { selectLoadingFlag } from '../selectors';
import ActiveLibrary, { styles as libraryStyle } from './ActiveLibrary';

export const styles = theme => ({
  addObservation: {
    right: theme.spacing(5),
    bottom: theme.spacing(5),
    width: 60,
    height: 60,
  },
  dialogContent: {
    minHeight: 300,
    paddingTop: theme.spacing(5),
  },
  adminLayout: {
    maxWidth: 'none',
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(4)}`,
  },
  labelHeader: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    fontSize: '1rem',
    color: theme.palette.text.dark,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    margin: 0,
    '&$disabled': {
      color: theme.palette.text.secondary,
    },
  },
  whiteSelectBtn: {
    backgroundColor: theme.palette.background.white,
    '&:hover:not(:disabled)': {
      backgroundColor: theme.palette.background.white,
    },
    '&:focus:not(:disabled)': {
      backgroundColor: theme.palette.background.white,
    },
  },
  tooltipAddTrade: {
    zIndex: 1301,
    position: 'absolute',
    right: 20,
    top: 88,
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      top: '-25px',
      right: '40px',
      borderStyle: 'solid',
      borderWidth: '1em',
      borderColor: `transparent transparent ${theme.palette.info.main} transparent`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    maxWidth: 300,
  },
  colorTextWhite: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  addNew: {
    width: '100%',
    borderRadius: '0px 0px 8px 8px',
    backgroundColor: theme.palette.background.white,
    color: theme.palette.secondary.main,
    justifyContent: 'flex-start',

    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    marginBlockStart: theme.spacing(3),
    width: '100%',
  },
  buttonTooltip: {
    textDecoration: 'none',
  },
  addObservationGenericButton: {
    textAlign: 'center',
    marginBlockEnd: theme.spacing(4),
    marginBlockStart: theme.spacing(4),
  },
  typographyEllipsisWidth: {
    maxWidth: '200px',
  },
});

/**
 * AddObservation
 */
function AddObservation({
  dispatchAddCommonObservation,
  dispatchAddTrade,
  dispatchSaveObservation,
  classes,
  intl,
  match,
  buttonType,
  history,
  loading,
  groups,
  canWriteLibrary,
  canActivateLibrary,
  canCreateObservation,
  beforeOpenDialog,
  openDialog,
  position,
  disableAddButton,
  showWriteLibraryTooltip,
  disableRedirect,
  addButtonLabel,
  onObservationCreated,
  source,
  onCloseDialog,
  moduleId: initialModuleId,
  dispatchFetchMoreTrade,
  buttonSize,
  customStyleButtonWrapper,
}) {
  const dispatch = useDispatch();
  const project = useContext(ProjectContext);
  const featureFlags = useFeatureFlags();
  const [activeDialog, setActiveDialog] = useState(false);
  const [activeAddTradeDialog, setActiveAddTradeDialog] = useState(false);
  const [activeAddCommonObservationDialog, setActiveAddCommonObservationDialog] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [description, setDescription] = useState('');
  const [selectedObservation, setSelectedObservation] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const moduleMap = useSelector(selectCurrentModuleMap());
  const moduleSettings = moduleMap?.get('settings');
  const { organizationId, idData, itemId } = match.params;
  const projectId = idData;
  const moduleId = initialModuleId || itemId;
  const textFieldLabel = moduleSettings?.get(MODULE_SETTINGS.descriptionStatus)
    ? intl.formatMessage(globalMessages.describe_observation)
    : intl.formatMessage(globalMessages.describe_observation_optional);

  const isGroupMandatory = moduleSettings?.get(MODULE_SETTINGS.is_group_mandatory);

  const canCreateItemInGroup = groups.some(group =>
    group?.authorizations?.includes(authorizations.project.createObservation),
  );

  const canCreateObservationInProject = project?.authorizations?.includes(
    authorizations.project.createObservation,
  );

  const shouldShowSelectGroups =
    (canCreateObservationInProject && isGroupMandatory) ||
    (!canCreateObservationInProject && canCreateItemInGroup);

  const { isOverSeatLimitOffer, currentPlan } = useOverSeatLimitOffer();

  useEffect(
    () => {
      dispatch(getCurrentModule(projectId, moduleId));
    },
    [dispatch, match.params],
  );

  useEffect(
    () => {
      if (openDialog) setActiveDialog(true);
    },
    [openDialog],
  );

  const getListRequestOptionTrade = onlyPinned => ({
    method: 'GET',
    url: `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/projects/${projectId}/trades`,
    dataKey: 'trades',
    namespace: 'filter1',
    urlParams: {
      only_pinned: onlyPinned,
      module_id: moduleId,
    },
    excludeParamsAtSearch: ['only_pinned'],
  });

  const listRequestOptionTrade = {
    ...getListRequestOptionTrade(true),
    callBack: data => {
      if (data?.trades?.length === 0) {
        dispatchFetchMoreTrade(getListRequestOptionTrade(false), 'filter1');
      }
      return data;
    },
  };

  const listRequestOptionObservations = {
    method: 'GET',
    url: `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/projects/${projectId}/trades/${selectedTrade?.id}/common-observations`,
    dataKey: 'common_observations',
    namespace: 'filter1',
  };

  const listRequestOptionGroups = {
    method: 'GET',
    url: `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.3/projects/${projectId}/groups/search`,
    dataKey: 'groups',
    namespace: 'filter2',
    callBack: data => {
      // Sort groups by name then put default group at the end
      if (!data?.sort) return data;
      const sortedGroups = data?.sort((grp1, grp2) =>
        grp1.get('name')?.localeCompare(grp2.get('name')),
      );
      const defaultGroup = sortedGroups?.find(grp => grp.get('is_default'));
      const otherGroups = sortedGroups?.filter(grp => !grp.get('is_default')) || [];

      return fromJS(defaultGroup ? [...otherGroups, defaultGroup] : [...otherGroups]);
    },
  };

  const handleRedirect = ({ id }) => {
    if (!disableRedirect) {
      history.push(Routing.observations.detail(organizationId, idData, itemId, id));
    }
    onObservationCreated();
    setActiveDialog(false);
    setSelectedTrade(null);
    setSelectedObservation(null);
    setDescription(undefined);
    setSelectedGroup(null);
  };

  const handleCreate = () => {
    let dueDate = null;
    if (
      featureFlags?.module_observation_creation_options_due_date &&
      moduleSettings?.get('due_date_days_offset')
    ) {
      const dueDateDaysOffset = moduleSettings?.get('due_date_days_offset');
      const dueDateDaysOffsetDate = moment().add(dueDateDaysOffset, 'days');
      dueDate = dueDateDaysOffsetDate.utc().format();
    }

    dispatchSaveObservation(
      selectedTrade,
      selectedObservation,
      selectedGroup,
      description,
      handleRedirect,
      projectId,
      moduleId,
      position,
      source,
      dueDate,
    );
  };

  const handleSelectChangeTrade = item => {
    setSelectedTrade(item);
    setSelectedObservation(null);
  };

  const handleSelectChangeObservation = item => {
    setSelectedObservation(item);
  };

  const handleSelectChangeGroup = item => {
    setSelectedGroup(item);
  };

  const renderPlaceHolder = (item, placeholder) => {
    if (!item) {
      return placeholder;
    }
    return getTranslation(item.names.translations, item?.name);
  };

  const handleShowDialog = () => {
    handleToggleDialog(setActiveDialog, !!(beforeOpenDialog && beforeOpenDialog()))();
  };

  const handleCancel = () => {
    Analytics.track('observations_creation_cancelled');
    handleToggleDialog(setActiveDialog, activeDialog)();
    setSelectedTrade(null);
    setSelectedObservation(null);
    setDescription(undefined);
    setSelectedGroup(null);
    if (onCloseDialog) onCloseDialog();
  };
  const handleCancelAddTrade = () => {
    handleToggleDialog(setActiveAddTradeDialog, activeAddTradeDialog)();
    handleToggleDialog(setActiveDialog, activeDialog)();
  };
  const handleCancelAddCommonObservation = () => {
    handleToggleDialog(setActiveAddCommonObservationDialog, activeAddCommonObservationDialog)();
    handleToggleDialog(setActiveDialog, activeDialog)();
  };

  const handleSaveNewTrade = trade => {
    setSelectedTrade(trade);
    handleToggleDialog(setActiveAddTradeDialog, activeAddTradeDialog)();
    handleToggleDialog(setActiveDialog, activeDialog)();
  };
  const handleSaveNewCommonObservation = observation => {
    setSelectedObservation(observation);
    handleToggleDialog(setActiveAddCommonObservationDialog, activeAddCommonObservationDialog)();
    handleToggleDialog(setActiveDialog, activeDialog)();
  };

  const addNewTradeButton = () => (
    <Button
      variant="outlined"
      onClick={() => {
        handleToggleDialog(setActiveDialog, activeDialog)();
        handleToggleDialog(setActiveAddTradeDialog, activeAddTradeDialog)();
      }}
      startIcon={<Add />}
    >
      {intl.formatMessage(globalMessages.add_new)}
    </Button>
  );

  const addNewCommonObservationButton = () => (
    <Button
      variant="outlined"
      onClick={() => {
        handleToggleDialog(setActiveDialog, activeDialog)();
        handleToggleDialog(setActiveAddCommonObservationDialog, activeAddCommonObservationDialog)();
      }}
      startIcon={<Add />}
    >
      {intl.formatMessage(globalMessages.add_new)}
    </Button>
  );

  const handleOnDescriptionChange = e => {
    setDescription(normalize(e.target.value));
  };

  const isSaveDisabled = () => {
    if (isOverSeatLimitOffer) {
      return true;
    }
    if (moduleSettings?.get(MODULE_SETTINGS.descriptionStatus) && !description) {
      return true;
    }
    if (moduleSettings?.get(MODULE_SETTINGS.descriptionStatus) && !description) {
      return true;
    }
    if (!moduleSettings?.get(MODULE_SETTINGS.libraryStatus) && !description) {
      return true;
    }
    if (shouldShowSelectGroups && !selectedGroup) {
      return true;
    }
    if (
      moduleSettings?.get(MODULE_SETTINGS.libraryStatus) &&
      (!selectedTrade || !selectedObservation)
    ) {
      return true;
    }
    return false;
  };

  const handleActiveLibraryChange = () => {
    setSelectedTrade(null);
    setSelectedObservation(null);
  };

  const handleRedirectTrade = () => {
    history.push(Routing.observations.categoryList(organizationId, idData, itemId));
  };

  const noDataRenderer = () => (
    <EmptyTable
      className={classes.noResultWrap}
      title={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link component="button" onClick={handleRedirectTrade}>
          <Typography component="p">
            {intl.formatMessage(globalMessages.trade_empty_table_title)}
          </Typography>
        </Link>
      }
      subtitle={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link component="button" onClick={handleRedirectTrade}>
          <Typography component="p">
            {intl.formatMessage(globalMessages.trade_empty_table_description)}
          </Typography>
        </Link>
      }
    />
  );

  const renderSelectors = () => {
    if (
      !moduleSettings?.get(MODULE_SETTINGS.libraryStatus) ||
      (!canWriteLibrary && !canCreateItemInGroup)
    ) {
      return null;
    }
    return (
      <>
        <InfiniteTableSelect
          disabled={!moduleSettings?.get(MODULE_SETTINGS.libraryStatus)}
          listRequestOption={listRequestOptionTrade}
          customStyle={{ width: '100%' }}
          noDataRenderer={noDataRenderer}
          placeholder={renderPlaceHolder(
            selectedTrade,
            intl.formatMessage(globalMessages.select_trade),
          )}
          selectButtonClassName={classNames({
            [classes.whiteSelectBtn]: canActivateLibrary,
          })}
          label={intl.formatMessage(globalMessages.trade)}
          onChange={handleSelectChangeTrade}
          addNewItemButton={shouldShowSelectGroups ? null : addNewTradeButton()}
        />

        <InfiniteTableSelect
          disabled={!selectedTrade || !moduleSettings?.get(MODULE_SETTINGS.libraryStatus)}
          listRequestOption={listRequestOptionObservations}
          selectButtonClassName={classNames({
            [classes.whiteSelectBtn]: canActivateLibrary,
          })}
          customStyle={{ width: '100%' }}
          placeholder={renderPlaceHolder(
            selectedObservation,
            intl.formatMessage(globalMessages.select_common_observation),
          )}
          label={intl.formatMessage(globalMessages.common_observations)}
          onChange={handleSelectChangeObservation}
          addNewItemButton={shouldShowSelectGroups ? null : addNewCommonObservationButton()}
        />
      </>
    );
  };

  const renderSelectGroups = () => (
    <InfiniteTableSelect
      listRequestOption={listRequestOptionGroups}
      customStyle={{ width: '100%' }}
      placeholder={
        selectedGroup?.name || intl.formatMessage(globalMessages.item_select_group_placeholder)
      }
      label={intl.formatMessage(globalMessages.groups)}
      onChange={handleSelectChangeGroup}
    />
  );

  const renderAdminLayout = () => (
    <ActiveLibrary
      moduleId={moduleId}
      handleActiveLibraryChange={handleActiveLibraryChange}
      className={classes.adminLayout}
    >
      {renderSelectors()}
    </ActiveLibrary>
  );

  /**
   * Render the component
   *
   * @return {JSX.Element}
   */
  return (
    <>
      {buttonType === 'generic' ? (
        <div className={classNames(classes.addObservationGenericButton, customStyleButtonWrapper)}>
          <Tooltip
            title={
              !canCreateObservation
                ? intl.formatMessage(globalMessages.upgrade_organization_account_for_observations)
                : ''
            }
          >
            <span>
              <Button
                size={buttonSize}
                onClick={handleShowDialog}
                startIcon={<Add />}
                disabled={!canCreateObservation || disableAddButton}
              >
                {buttonSize === 'small' ? (
                  addButtonLabel || intl.formatMessage(globalMessages.create_an_observation)
                ) : (
                  <TypographyEllipsis
                    variant="subtitleWhite"
                    className={classes.typographyEllipsisWidth}
                  >
                    {addButtonLabel || intl.formatMessage(globalMessages.create_an_observation)}
                  </TypographyEllipsis>
                )}
              </Button>
            </span>
          </Tooltip>
        </div>
      ) : (
        <FabButton
          className={classes.addObservation}
          onClick={handleToggleDialog(setActiveDialog, activeDialog)}
        />
      )}

      {activeDialog &&
        (canWriteLibrary && showWriteLibraryTooltip) && (
          <div className={classes.tooltipAddTrade}>
            <div className={classes.tooltip}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                onClick={handleRedirectTrade}
                className={classes.buttonTooltip}
              >
                <Typography component="p" className={classes.colorTextWhite}>
                  {intl.formatMessage(globalMessages.library_settings)}
                </Typography>
                <Typography component="p" className={classes.colorTextWhite}>
                  {intl.formatMessage(globalMessages.tooltip_library_settings_description)}
                </Typography>
              </Link>
            </div>
          </div>
        )}
      <Dialog
        id="dialog-add-observation"
        open={activeDialog}
        dialogContentClasses={{ root: classes.dialogContent }}
        fullWidth
        maxWidth="sm"
        onCloseDialog={handleCancel}
        dialogTitleNode={intl.formatMessage(globalMessages.create_an_observation)}
        dialogActionNode={[
          <Button onClick={handleCancel} key="no" variant="plain">
            {intl.formatMessage(globalMessages.cancel)}
          </Button>,
          <Button
            disabled={isSaveDisabled()}
            form="create-observation-form"
            key="yes"
            onClick={handleCreate}
            type="submit"
            prevent
          >
            {intl.formatMessage(globalMessages.create)}
          </Button>,
        ]}
      >
        {loading && <LoadingIndicator size="40" />}

        <form id="create-observation-form">
          <FormControlLabel
            classes={{ root: classes.labelHeader, label: classes.label }}
            fullWidth
            control={
              <TextField
                autoFocus
                value={description}
                name="observationDescription"
                fullWidth
                variant="standard"
                onChange={handleOnDescriptionChange}
                placeholder={textFieldLabel}
                InputProps={{ classes: { input: classes.inputUserText }, disableUnderline: true }}
              />
            }
            label=""
          />
          {canActivateLibrary ? renderAdminLayout() : renderSelectors()}
          {shouldShowSelectGroups && renderSelectGroups()}
          {isOverSeatLimitOffer && (
            <Box sx={{ marginTop: '20px' }}>
              <OverSeatOfferBanner currentPlan={currentPlan} fullWidth blockItemsCreation />
            </Box>
          )}
        </form>
      </Dialog>
      <AddNewItemDialog
        activeDialog={activeAddTradeDialog}
        handleCancel={handleCancelAddTrade}
        dialogTitleNode={intl.formatMessage(globalMessages.add_a_trade)}
        placeholder={intl.formatMessage(globalMessages.name_your_trade)}
        callBack={handleSaveNewTrade}
        dispatchCreateItem={dispatchAddTrade}
        dataKey="trades"
        classes={classes}
        moduleId={moduleId}
      />
      <AddNewItemDialog
        activeDialog={activeAddCommonObservationDialog}
        handleCancel={handleCancelAddCommonObservation}
        dialogTitleNode={intl.formatMessage(globalMessages.add_common_observation)}
        placeholder={intl.formatMessage(globalMessages.name_common_observation)}
        callBack={handleSaveNewCommonObservation}
        dispatchCreateItem={dispatchAddCommonObservation}
        dataKey="common-observations"
        classes={classes}
        tradeId={selectedTrade?.id}
      />
    </>
  );
}

AddObservation.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  canWriteLibrary: PropTypes.bool,
  showWriteLibraryTooltip: PropTypes.bool,
  canActivateLibrary: PropTypes.bool,
  classes: PropTypes.object,
  buttonType: PropTypes.string,
  intl: intlShape.isRequired,
  groups: PropTypes.array,
  disableAddButton: PropTypes.bool,
  moduleId: PropTypes.string,
  beforeOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  position: PropTypes.object,
  disableRedirect: PropTypes.bool,
  addButtonLabel: PropTypes.string,
  onObservationCreated: PropTypes.func,
  dispatchSaveObservation: PropTypes.func,
  dispatchAddTrade: PropTypes.func,
  dispatchAddCommonObservation: PropTypes.func,
  dispatchFetchMoreTrade: PropTypes.func,
  canCreateObservation: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  source: PropTypes.string,
  buttonSize: PropTypes.string,
  customStyleButtonWrapper: PropTypes.string,
};

AddObservation.defaultProps = {
  classes: {},
  buttonType: 'generic',
  canCreateObservation: false,
  groups: [],
  onObservationCreated: () => {},
  showWriteLibraryTooltip: true,
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingFlag(),
  groups: selectGroups(),
});

const mapDispatchToProps = {
  dispatchSaveObservation: saveObservation,
  dispatchAddTrade: addTrade,
  dispatchAddCommonObservation: addCommonObservation,
  dispatchFetchMoreTrade: getItems,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  injectIntl,
  withRouter,
  withStyles(combineStyles(styles, formEngineStyle, libraryStyle)),
)(AddObservation);
